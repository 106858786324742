<template>
    <div class="button_group">
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP}"
                @click="changeKind(leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP)">프리미엄
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE}"
                @click="changeKind(leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE)">슈퍼리그
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP}"
                @click="changeKind(leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP)">유로컵
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP}"
                @click="changeKind(leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP)">월드컵
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_BET365_BASKETBALL_WARTER}"
                @click="changeKind(leisureConst.LEISURE_KIND_BET365_BASKETBALL_WARTER)">농구(워터포드)
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_BET365_BASKETBALL_BAKER}"
                @click="changeKind(leisureConst.LEISURE_KIND_BET365_BASKETBALL_BAKER)">농구(베이커)
        </button>
        <button class="bw4"
                :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK}"
                @click="changeKind(leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK)">골든힐
        </button>
        <button class="bw4"
                :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK}"
                @click="changeKind(leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK)">힐사이드
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_BET365_HORCERACE}"
                @click="changeKind(leisureConst.LEISURE_KIND_BET365_HORCERACE)">가상경마
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_POWERBALL}"
                @click="changeKind(leisureConst.LEISURE_KIND_POWERBALL)">파워볼
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_POWERSADALI}"
                @click="changeKind(leisureConst.LEISURE_KIND_POWERSADALI)">파워사다리
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_SPEEDKINO}"
                @click="changeKind(leisureConst.LEISURE_KIND_SPEEDKINO)">스피드키노
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_KINOSADALI}"
                @click="changeKind(leisureConst.LEISURE_KIND_KINOSADALI)">키노사다리
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_STAR_SD_1}"
                @click="changeKind(leisureConst.LEISURE_KIND_STAR_SD_1)">별다리1분
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_STAR_SD_2}"
                @click="changeKind(leisureConst.LEISURE_KIND_STAR_SD_2)">별다리2분
        </button>
        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_STAR_SD_3}"
                @click="changeKind(leisureConst.LEISURE_KIND_STAR_SD_3)">별다리3분
        </button>
<!--        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_NAMED_DALIDALI}"-->
<!--                @click="changeKind(leisureConst.LEISURE_KIND_NAMED_DALIDALI)">N다리다리-->
<!--        </button>-->
<!--        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_NAMED_SADALI}"-->
<!--                @click="changeKind(leisureConst.LEISURE_KIND_NAMED_SADALI)">N사다리-->
<!--        </button>-->
<!--        <button class="bw4" :class="{'active': kind === leisureConst.LEISURE_KIND_NAMED_SPEEDSADALI}"-->
<!--                @click="changeKind(leisureConst.LEISURE_KIND_NAMED_SPEEDSADALI)">NS사다리-->
<!--        </button>-->
<!--        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_NAMED_SNAIL}"-->
<!--                @click="changeKind(leisureConst.LEISURE_KIND_NAMED_SNAIL)">N달팽이-->
<!--        </button>-->
<!--        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_FX_1M}"-->
<!--                @click="changeKind(leisureConst.LEISURE_KIND_FX_1M)">Fx1m-->
<!--        </button>-->
<!--        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_FX_2M}"-->
<!--                @click="changeKind(leisureConst.LEISURE_KIND_FX_2M)">Fx2m-->
<!--        </button>-->
<!--        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_FX_3M}"-->
<!--                @click="changeKind(leisureConst.LEISURE_KIND_FX_3M)">Fx3m-->
<!--        </button>-->
<!--        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_FX_4M}"-->
<!--                @click="changeKind(leisureConst.LEISURE_KIND_FX_4M)">Fx4m-->
<!--        </button>-->
<!--        <button class="bw4" :class="{'sub_button_active': kind === leisureConst.LEISURE_KIND_FX_5M}"-->
<!--                @click="changeKind(leisureConst.LEISURE_KIND_FX_5M)">Fx5m-->
<!--        </button>-->
        <button class="bw4" style="background: transparent"></button>
    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureGameLinksComp",
        data() {
            return {
                sportsConst,
                leisureConst,
                kind: leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP,
            }
        },
        methods: {
            changeKind(kind) {
                //LeisureGameResult.vue -> $on
                this.kind = kind
                this.$emit('leisureKindChanged', kind);
            }
        }
    }
</script>

<style scoped>
    .sub_button_active {
        color: #e5af2a !important;
        border: 1px solid var(--activeLeisuerGame1);
    }
</style>