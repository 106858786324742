<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>미니게임 결과</sub-title>
                <game-result-links></game-result-links>
                <leisure-game-links-comp @leisureKindChanged="leisureKindChanged"></leisure-game-links-comp>
                <div class="board_list">

                    <!--파워볼결과-->
                    <table v-if="kind === leisureConst.LEISURE_KIND_POWERBALL">
                        <tr>
                            <th style="width: 20%">당일회차</th>
                            <th style="width: 25%">시작일시</th>
                            <th>결과</th>
                        </tr>
                        <tbody v-for="item in resultList">
                        <tr v-if="item.display === sportsConst.ENABLE" class="tr">

                            <td>{{item.sequence}}</td>
                            <td>{{item.startTime|datef('MM-DD HH:mm')}}</td>
                            <td class="text-primary result"
                                v-if="!item.cancel && !item.specialcase">
                                <p>
                                    <span>{{item.leisureGameResult.ball1 + ','}}</span>
                                    <span>{{item.leisureGameResult.ball2 + ','}}</span>
                                    <span>{{item.leisureGameResult.ball3 + ','}}</span>
                                    <span>{{item.leisureGameResult.ball4 + ','}}</span>
                                    <span>{{item.leisureGameResult.ball5 + ':'}}</span>
                                    <span class="text-primary">{{item.leisureGameResult.powerball}}</span>
                                </p>
                                <p style="color: #089fd4">
                                    {{item.leisureGameResult.pbOddevenText}},
                                    {{item.leisureGameResult.pbOverunderText}},
                                    {{item.leisureGameResult.pbInterval}},
                                    {{item.leisureGameResult.pbOddevenOverunderText}}
                                </p>
                                <p>
                                    {{item.leisureGameResult.nbOddevenText}},
                                    {{item.leisureGameResult.nbOverunderText}},
                                    {{item.leisureGameResult.nbInterval}},
                                    {{item.leisureGameResult.nbBmsText}},
                                    {{item.leisureGameResult.nbOddevenOverunderText}},
                                    {{item.leisureGameResult.nbOddevenBmsText}}
                                </p>
                            </td>
                            <td v-if="item.cancel">
                                취소
                            </td>
                            <td v-if="item.specialcase">
                                적특
                            </td>
                        </tr>
                        </tbody>
                    </table>


                    <!--사다리 결과-->
                    <table class="table" v-if="kind === leisureConst.LEISURE_KIND_POWERSADALI
                || kind === leisureConst.LEISURE_KIND_POWERSADALI
                 || kind === leisureConst.LEISURE_KIND_KINOSADALI
                 || kind === leisureConst.LEISURE_KIND_NAMED_DALIDALI
                 || kind === leisureConst.LEISURE_KIND_NAMED_SADALI
                 || kind === leisureConst.LEISURE_KIND_NAMED_SPEEDSADALI
                 || kind === leisureConst.LEISURE_KIND_KAKAO_SD_1 || kind === leisureConst.LEISURE_KIND_KAKAO_SD_2 || kind === leisureConst.LEISURE_KIND_KAKAO_SD_3
                 || kind === leisureConst.LEISURE_KIND_STAR_SD_3|| kind === leisureConst.LEISURE_KIND_STAR_SD_2|| kind === leisureConst.LEISURE_KIND_STAR_SD_1">
                        <tr>
                            <th style="width: 25%">당일회차</th>
                            <th style="width: 25%">시작일시</th>
                            <th>결과</th>
                        </tr>
                        <tbody v-for="(item,idx) in resultList" :key="idx">
                        <tr v-if="item.display === sportsConst.ENABLE" class="tr">
                            <td>{{item.sequence}}</td>
                            <td>{{item.startTime|datef('MM-DD HH:mm')}}</td>
                            <td class="text-primary result"
                                v-if="!item.cancel && !item.specialcase">
                                <span>{{item.leisureGameResult.oddevenText + ','}}</span>
                                <span>{{item.leisureGameResult.lineCountText + ','}}</span>
                                <span>{{item.leisureGameResult.leftorrightText}}</span>
                            </td>
                            <td v-if="item.cancel">
                                취소
                            </td>
                            <td v-if="item.specialcase">
                                적특
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <!--스피드키노 결과-->
                    <table class="table" v-if="kind === leisureConst.LEISURE_KIND_SPEEDKINO">
                        <tr>
                            <th style="width: 25%">당일회차</th>
                            <th style="width: 25%">시작일시</th>
                            <th>결과</th>
                        </tr>
                        <tbody v-for="(item,idx) in resultList" :key="idx">
                        <tr v-if="item.display === sportsConst.ENABLE" class="tr">
                            <td>{{item.sequence}}</td>
                            <td>{{item.startTime|datef('MM-DD HH:mm')}}</td>
                            <td class="text-primary result"
                                v-if="!item.cancel && !item.specialcase">
                                <span>{{item.leisureGameResult.oddevenText + ','}}</span>
                                <span>{{item.leisureGameResult.overunderText}}</span>
                            </td>
                            <td v-if="item.cancel">
                                취소
                            </td>
                            <td v-if="item.specialcase">
                                적특
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <!--Fx 결과-->
                    <table class="table" v-if="kind === leisureConst.LEISURE_KIND_FX_1M
        || kind === leisureConst.LEISURE_KIND_FX_2M
        || kind === leisureConst.LEISURE_KIND_FX_3M
        || kind === leisureConst.LEISURE_KIND_FX_4M
        || kind === leisureConst.LEISURE_KIND_FX_5M">
                        <tr>
                            <th style="width: 25%">당일회차</th>
                            <th style="width: 25%">시작일시</th>
                            <th>결과</th>
                        </tr>
                        <tbody v-for="(item,idx) in resultList" :key="idx">
                        <tr v-if="item.display === sportsConst.ENABLE" class="tr">
                            <td>{{item.sequence}}</td>
                            <td>{{item.startTime|datef('MM-DD HH:mm')}}</td>
                            <td class="text-primary result"
                                v-if="!item.cancel && !item.specialcase">
                                <span>{{item.leisureGameResult.oddevenText + ','}}</span>
                                <span>{{item.leisureGameResult.overunderText + ','}}</span>
<!--                                <span>{{'시가'+item.leisureGameResult.cgSellbuyText+','}}</span>-->
                                <span>{{'종가'+item.leisureGameResult.jgSellbuyText}}</span>
                            </td>
                            <td v-if="item.cancel">
                                취소
                            </td>
                            <td v-if="item.specialcase">
                                적특
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <!--N 달팽이 결과-->
                    <table class="table" v-if="kind === leisureConst.LEISURE_KIND_NAMED_SNAIL">
                        <tr>
                            <th style="width: 25%">당일회차</th>
                            <th style="width: 25%">시작일시</th>
                            <th>결과</th>
                        </tr>
                        <tbody v-for="(item,idx) in resultList" :key="idx">
                        <tr v-if="item.display === sportsConst.ENABLE" class="tr">
                            <td>{{item.sequence}}</td>
                            <td>{{item.startTime|datef('MM-DD HH:mm')}}</td>
                            <td class="text-primary result"
                                v-if="!item.cancel && !item.specialcase">
                                <span>{{item.leisureGameResult.oddevenText + ','}}</span>
                                <span>{{item.leisureGameResult.overunderText}}</span>
                                <span>{{item.leisureGameResult.winplayerText + '번팽이'}}</span>
                            </td>
                            <td v-if="item.cancel">
                                취소
                            </td>
                            <td v-if="item.specialcase">
                                적특
                            </td>
                        </tr>
                        </tbody>
                    </table>


                    <!--Bet365개경주 결과-->
                    <table class="table" v-if="kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
        || kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK || kind === leisureConst.LEISURE_KIND_BET365_HORCERACE">
                        <tr>
                            <th style="width: 25%">당일회차</th>
                            <th style="width: 25%">시작일시</th>
                            <th>결과</th>
                        </tr>
                        <tbody v-for="(item,idx) in resultList" :key="idx">
                        <tr v-if="item.display === sportsConst.ENABLE" class="tr">
                            <td>{{item.sequence}}</td>
                            <td>{{item.startTime|datef('MM-DD HH:mm')}}</td>
                            <td class="text-primary result"
                                v-if="!item.cancel && !item.specialcase">
                                <span>{{item.leisureGameResult.first + '번 우승'}}</span>
                            </td>
                            <td v-if="item.cancel">
                                취소
                            </td>
                            <td v-if="item.specialcase">
                                적특
                            </td>
                        </tr>
                        </tbody>
                    </table>


                    <!--Bet365 축구 결과-->
                    <table class="table" v-if="kind === leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
        || kind === leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
        || kind === leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP
        || kind === leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP
        || kind === leisureConst.LEISURE_KIND_BET365_BASKETBALL_BAKER || kind === leisureConst.LEISURE_KIND_BET365_BASKETBALL_WARTER">
                        <tr>
                            <th style="width: 25%">당일회차</th>
                            <th style="width: 25%">시작일시</th>
                            <th>결과</th>
                        </tr>
                        <tbody v-for="(item,idx) in resultList" :key="idx">
                        <tr v-if="item.display === sportsConst.ENABLE" class="tr">
                            <td>{{item.sequence}}</td>
                            <td>{{item.startTime|datef('MM-DD HH:mm')}}</td>
                            <td class="text-primary result"
                                v-if="!item.cancel && !item.specialcase">
                                <p style="display: block">{{item.homeTeamName}}({{item.homeTeamOdds}}) vs
                                    {{item.awayTeamName}}({{item.awayTeamOdds}})</p>

                                <p style="display: block;box-sizing: border-box; padding: 2px 0">
                                    {{item.homeTeamScore + ':'+ item.awayTeamScore}}
                                    <span v-if="item.sportsKind === sportsConst.GAME_KIND_1X2">
                  <i v-if="item.leisureGameResult.winner === sportsConst.GAME_RESULT_WIN_HOME">홈승</i>
                  <i v-if="item.leisureGameResult.winner === sportsConst.GAME_RESULT_DRAW">무</i>
                  <i v-if="item.leisureGameResult.winner === sportsConst.GAME_RESULT_WIN_AWAY">원정승</i>
                </span>
                                    <span v-if="item.sportsKind === sportsConst.GAME_KIND_OVERUNDER">
                  <i v-if="item.leisureGameResult.winner === sportsConst.GAME_RESULT_WIN_HOME">오버</i>
                  <i v-if="item.leisureGameResult.winner === sportsConst.GAME_RESULT_WIN_AWAY">언더</i>
                </span>
                                </p>

                            </td>
                            <td v-if="item.cancel">
                                취소
                            </td>
                            <td v-if="item.specialcase">
                                적특
                            </td>
                        </tr>
                        </tbody>
                    </table>


                </div>
                <pagination :page-index=" pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"></pagination>
            </div>
        </div>

        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>
    </div>

</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import SubTitle from "../../components/SubTitle";
    import leisureConst from "../../common/leisureConst";
    import sportsConst from "../../common/sportsConst";
    import {getLeisureGameReulst} from "../../network/leisureRequest";
    import Pagination from "../../components/pagenation/Pagination";
    import LeisureGameLinksComp from "../../components/leisuregame/LeisureGameLinksComp";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import GameResultLinks from "../../components/GameResultLinks";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import UserInfoComp from "../../components/UserInfoComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import {postionMixin} from "../../common/mixin";

    export default {
        name: "LeisureGameResult",
        mixins:[postionMixin],
        components: {
            RightButtonsComp,
            SportsBetCartComp,
            UserInfoComp,
            GameResultLinks,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp,
            TopbarComp,
            ScrollTopComp, LeisureGameLinksComp, Pagination, SubTitle, LeisureLeftComp, LeftBarComp, RightBarComp
        },
        data() {
            return {
                leisureConst,
                sportsConst,
                pageNum: 1,
                pageSize: 20,
                total: 1,
                orderBy: 'lg.id desc',
                kind: leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP,
                resultList: [],
                position:"경기결과(M)",
            }
        },
        methods: {
            getLeisureGameResult() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getLeisureGameReulst(this.kind, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    if (res.data.success) {
                        this.total = res.data.total
                        this.resultList = res.data.data
                        this.resultList.forEach(g => {
                            try {
                                g.cancel = false
                                g.specialcase = false
                                g.leisureGameResult = JSON.parse(g.leisureGameResult)
                            } catch (e) {
                                if (g.leisureGameResult === leisureConst.LEISURE_RESULT_CANCEL_TEXT) {
                                    g.cancel = true
                                }
                                if (g.leisureGameResult === leisureConst.LEISURE_RESULT_SPECIALCASE_TEXT) {
                                    g.specialcase = true
                                }
                            }
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            pageChange(page) {
                this.pageNum = page
                this.getLeisureGameResult()
            },
            leisureKindChanged(kind) {
                this.kind = kind;
                this.pageNum = 1
            }
        },
        created() {
            this.getLeisureGameResult();
        },
        watch: {
            kind() {
                this.getLeisureGameResult();
            }
        }
    }
</script>

<style scoped>
    .result p {
        display: inline-block;
        margin-right: 5px;
    }

    @media screen and (max-width: 800px) {
        .result p {
            display: block;
            margin-right: 0;
        }
    }
</style>